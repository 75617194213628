<template>
	<!-- 标的物设置 -->
	<div class="mod-config">
		<el-card style="height:650px">
			<el-row>
				<el-col :span="pageSpan" v-if="1 === 0 && showAdd">
					<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline"
						@click="addOrUpdateHandle()">新增项目人员</el-button>
				</el-col>
				<!-- <el-col :span="pageSpan" style="text-align: right;">
				<el-pagination
					background
					@current-change="currentChangeHandle"
					layout="prev, pager, next"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="totalPage">
				</el-pagination>
			</el-col> -->
			</el-row>
			<div class="cardList">
				<div class="cardListItem" v-for="(item, index) in dataList">
					<el-card body-style="padding: 30px 20px;" style="background:#E1F1FF">
						<el-row>
							<el-col :span="8" style="color: #727580;">姓名</el-col>
							<el-col :span="16" style="color: #00428e; font-size: 16px;">{{ item.personName }}</el-col>
						</el-row>
						<el-row>
							<el-col :span="8" style="color: #727580;">职务</el-col>
							<el-col :span="16" style="color: #00428e; font-size: 16px;">{{ item.jobKindName }}</el-col>
						</el-row>
						<!--					<el-row>-->
						<!--						<el-col :span="8" style="color: #727580;">专业</el-col>-->
						<!--						<el-col :span="16" style="color: #00428e; font-size: 16px;">{{item.personMajorName}}</el-col>-->
						<!--					</el-row>-->
						<!--					<el-row style="text-align: center; height: 42px;">-->
						<!--						<i class="el-icon-more" style="font-size: 32px; color: #e3e3e3;" v-if="item.subjectParamInfo && item.subjectParamInfo.length > 3"></i>-->
						<!--					</el-row>-->
						<div style="text-align: center;" v-if="1 === 0 && (showEdit || showDelete)">
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
								icon="el-icon-edit-outline" @click="addOrUpdateHandle(item.recId)"
								v-if="showEdit">修改</el-button>
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain icon="el-icon-delete"
								@click="deleteHandle(item.recId)" v-if="showDelete">删除</el-button>
						</div>
					</el-card>
				</div>
			</div>
			<el-pagination class="my-pagination" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
				:current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</el-card>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>

<script>
import AddOrUpdate from "./personnelCommun-add-or-update"
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showAdd: false,
			showSearch: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 24,
			addOrUpdateVisible: false,
			dataList: [],
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		}
	},
	components: {
		AddOrUpdate
	},
	mounted() {
		this.showAdd = $common.isAuth('person:add');
		this.showSearch = $common.isAuth('person.search');
		this.showEdit = $common.isAuth('person:edit');
		this.showDelete = $common.isAuth('person:delete');
		// if(!this.showAdd){
		// 	this.pageSpan = 24;
		// }
		this.getDataList();
	},
	methods: {
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectpersoninfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
					}
				});
			}
		},
		currentChangeHandle(val) {
			if (val) {
				this.pageIndex = val
			} else {
				this.pageIndex = 1
			}
			this.getDataList()
		},
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		// 删除
		deleteHandle(id) {
			var ids = id ? [id] : this.dataListSelections.map(item => {
				return item.recNo
			})
			this.$confirm(`确定对[id=${ids.join(',')}]进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectpersoninfo/delete",
					method: 'post',
					data: this.$http.adornData(ids, false)
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
	}
}
</script>

<style lang="scss" scoped="scoped">
.cardList {
	margin-top: -15px;
	margin-left: -8px;
	height: 550px;
	// max-height: 520px;
	.cardListItem {
		margin-top: 15px;
		display: inline-block;
		vertical-align: top;
		width: 20%;
		padding: 0 8px;
		line-height: 32px;
	}
}

.my-pagination {
	margin-top: 50px !important;
	margin-right: 10px !important;
}
</style>
